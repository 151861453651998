import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import Image from '../components/Image';

const AboutPage = () => {
  const data = useStaticQuery(graphql`
    query {
      aboutImg: file(relativePath: { eq: "about.jpeg" }) {
        childImageSharp {
          gatsbyImageData(width: 500, placeholder: BLURRED, formats: [AUTO, WEBP, AVIF])
        }
      }
    }
  `);
  const headerSection = <h1 className="mt-0 mb-5 text-center text-4xl">About</h1>;
  const descriptionContent = (
    <p className="mx-auto max-w-3xl text-center">
      Sit amet commodo nulla facilisi nullam vehicula ipsum a arcu. Vitae et leo duis ut. Sed odio
      morbi quis commodo odio aenean sed adipiscing.
    </p>
  );
  const cardsContent = (
    <div className="container relative mx-auto grid max-w-6xl gap-10 px-3 py-10 text-center text-slate-800 lg:mt-10 lg:grid-cols-3 ">
      <div className="mt-10 rounded-xl border border-slate-300 p-5 text-slate-800 dark:border-slate-500 dark:text-white xl:p-10">
        <div className="mx-auto flex h-24 w-24 justify-center rounded-full bg-slate-700 text-center text-5xl">
          <span className="my-auto">🚀</span>
        </div>
        <h3 className="mb-5">Pulvinar elementum</h3>
        <p className="mb-0">
          labore et dolore magna semper feugiat nibh. Mi ipsum faucibus vitae aliquet nec
          ullamcorper sit amet risus.
        </p>
      </div>
      <div className="rounded-xl border border-slate-300 p-5 text-slate-800 dark:border-slate-500 dark:text-white xl:p-10">
        <div className="mx-auto flex h-24 w-24 justify-center rounded-full bg-slate-700 text-center text-5xl">
          <span className="my-auto">🖥️</span>
        </div>
        <h3 className="mb-5">Integer enim neque</h3>
        <p className="mb-0">
          Magna aliqua Id venenatis a condimentum vitae sapien pellentesque habitant. Consecte
          adipiscing elit, sed do eiusmod tempor incididunt ut.
        </p>
      </div>
      <div className="rounded-xl border border-slate-300 p-5 text-slate-800 dark:border-slate-500 dark:text-white lg:mt-10 xl:p-10">
        <div className="mx-auto flex h-24 w-24 justify-center rounded-full bg-slate-700 text-center text-5xl">
          <span className="my-auto">📲</span>
        </div>
        <h3 className="mb-5">Gravida rutrum</h3>
        <p className="mb-0">
          Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt
          ut.
        </p>
      </div>
    </div>
  );
  const briefText = (
    <div className="mt-10 flex space-x-10">
      <article className="mt-10">
        <Image
          className="mb-10 max-h-[32rem] max-w-sm rounded-lg lg:float-right lg:ml-10 lg:max-w-md"
          filename={data.aboutImg}
          title="About me"
        />
        <h2 className="mt-0">Tempor orci eu lobortis elementum molestie.</h2>
        <p>
          Tempor orci eu lobortis elementum. Sed velit dignissim sodales ut eu sem integer vitae
          justo.
        </p>
        <p>
          Fermentum iaculis eu non diam phasellus vestibulum. Risus viverra adipiscing at in tellus.
          Egestas integer eget aliquet nibh praesent tristique magna. Nunc scelerisque viverra
          mauris in aliquam sem fringilla. Pellentesque habitant morbi tristique senectus et.
        </p>

        <p>
          Integer eget aliquet nibh praesent tristique magna sit amet purus. Nulla facilisi nullam
          vehicula ipsum. Lectus sit amet est placerat in egestas erat imperdiet. Eleifend quam
          adipiscing vitae proin sagittis nisl rhoncus mattis rhoncus.
        </p>
        <p>Felis donec et odio pellentesque diam volutpat.</p>
      </article>
    </div>
  );

  return (
    <>
      {headerSection}
      {descriptionContent}
      <hr className=" mx-auto my-3 max-w-lg border-2 border-slate-300 dark:border-slate-700" />
      {cardsContent}
      {briefText}
    </>
  );
};

export default AboutPage;
